import * as React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import './styles.scss'

const CalloutWithImage = ({ className }) => {
    return (
        <div className={`c-callout-with-image${className && ` ${className}`}`}>
            <div className="row">
                <div className="col-lg-5 c-callout-with-image__content-column">
                    <div>
                        <span className="c-callout-with-image__title c-callout-with-image__title--primary">
                            Probably the most important thing you can do for
                            your family today.
                        </span>
                        <span className="c-callout-with-image__title c-callout-with-image__title--secondary">
                            Protect the ones you love.
                        </span>
                    </div>
                </div>
                <div className="col-lg-7 c-callout-with-image__image-column">
                    <StaticImage
                        src="../../images/family.jpg"
                        alt="View from above of family smiling and hugging on the floor"
                    />
                </div>
            </div>
        </div>
    )
}

CalloutWithImage.propTypes = {
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

CalloutWithImage.defaultProps = {
    className: ''
}

export default CalloutWithImage
